import React from "react"
import LayoutCourse from "../../../layouts/course"
import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import CourseFooterNext from "../../../components/course-footer-next"
import InfoBox from "../../../components/info-box"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import { getCourseNavigation } from "../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin"
        />
      }
      navigation={navigation}
    >
      <Seo title="Mit Hölderlin auf Reisen" />
      <Box>
        <Stack>
          <Columns
            alignY="center"
            alignX="space-between"
            collapse={[true, false]}
          >
            <Column>
              <Text sans>Ab 3. und 4. Klasse</Text>
            </Column>
            <Column width="content">
              <TextLink to="/kurse/hoelderlins-reisen/fuer-lehrer">
                Informationen für Lehrer*innen
              </TextLink>
            </Column>
          </Columns>
          <Heading as="h2" level={2}>
            Mit Hölderlin auf Reisen
          </Heading>
          <InfoBox title="Um was geht es?">
            Auf seiner ersten größeren Reise hat Hölderlin eine Menge gesehen
            und erlebt. Im Kurs sehen wir uns die Briefe an, in denen er seiner
            Mutter von seinen Reise-Abenteuern berichtete. Außerdem näherst du
            dich einem Gedicht, das Hölderlin später zu dieser Reise verfasst
            hat. Du lauschst seiner Melodie und versuchst es selbst laut
            vorzutragen.
          </InfoBox>
          <InfoBox title="Was ist dein Ziel?">
            Im 1. Kapitel lernst den Dichter Friedrich Hölderlin kennen,
            begleitest ihn ein Stück auf seiner Reise und überlegst dir eine
            eigene Fortsetzung. Zum Schluss führst du sein Tagebuch selbst fort
            und erzählst, wie die Reise weiter verlaufen ist. Im 2. Kapitel
            entzifferst du den Namen einer Stadt, die Hölderlin auf seiner Reise
            besonders gut gefallen hat. Du erfährst mehr über das Gedicht, das
            Hölderlin über diese Stadt geschrieben hat und trägst es am Ende
            selbst vor.
          </InfoBox>
          <InfoBox title="Was lernst du in diesem Kurs?">
            Du erfährst etwas über den Dichter Friedrich Hölderlin und die Zeit,
            in der er gelebt hat. Du blätterst in seinem Reisetagebuch,
            errechnest seine Reisekosten, korrigierst seine Rechtschreibfehler
            und versuchst, dich in seine Rolle hinein zu versetzen. An einem
            seiner Gedichte verfolgst du, wie er mit Worten, Silben und Klängen
            gespielt hat, und lernst mehr über die Arbeit von
            Schriftstellerinnen und Schriftstellern.
          </InfoBox>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
